<template>
  <v-container>
    <v-row
      justify="start"
      align="center"
      class="mt-4"
    >
      <div
          style="width: 150px"
          class="mr-10"
      >
        <v-select
            label="Customer"
            :items="compositeCustomersList"
            v-model="currentCompositeCustomer"
            item-text="label"
            item-value="id"
            return-object
            @change="changeCustomer"
        />
      </div>

      <div
          class="mr-3 invisible"
      >
        <v-btn-toggle>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  v-bind="attrs"
                  v-on="on"
                  active-class="no-active"
                  :disabled="isScrollButtonsDisabled"
                  @click="setPeriodStep('prev')"
              >
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
            </template>
            Previous period
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  v-bind="attrs"
                  v-on="on"
                  :disabled="isDisabledNextButton || isScrollButtonsDisabled"
                  active-class="no-active"
                  @click="setPeriodStep('next')"
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </template>
            Next period
          </v-tooltip>
        </v-btn-toggle>
      </div>

      <div
          style="width: 200px"
          class="mr-3"
      >
        <v-select
            label="Period type"
            :items="periodSelect"
            v-model="periodSelectedComputed"
            @change="selectPeriod"
        >
          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title
                  v-text="item.text"
              ></v-list-item-title>
            </v-list-item-content>
          </template>
        </v-select>
        <v-dialog
            v-model="isDatepickerDialogOpen"
            persistent
            width="290px"
        >
          <v-date-picker
              v-model="customDateRange"
              range
              :max="formattedDate()"
          >
            <v-spacer></v-spacer>
            <v-btn
                text
                color="primary"
                @click="cancelDateRangeDialog"
            >
              Cancel
            </v-btn>
            <v-btn
                text
                color="primary"
                @click="saveDataRange"
                :disabled="isEmptyCustomDateRange"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </div>

      <div
          v-if="periodSelected === 'custom'"
          style="width: 300px; font-weight: bold"
          class="mr-10 item-clicker"
          @click="openDateRangeDialog"
      >
        {{ shownDate }}
      </div>
      <div
          v-else
          style="width: 300px"
          class="mr-10"
      >
        {{ shownDate }}
      </div>

      <div
          class="mr-10"
      >
        <v-btn
            :color="!isFiltersChanged ? 'info' : 'warning' "
            class="ma-2"
            @click="getData"
            :outlined="!isFiltersChanged"
        >
          {{ isFiltersChanged ? 'Update' : 'Updated ' }}
        </v-btn>
      </div>
      <div
          v-if="hasAnyAbility([
                $constants.ABILITY.EXPORT,
            ])"
          class="mr-10"
      >
        <v-btn
            color="info"
            class="ma-2"
            @click="exportData"
        >
          Export
        </v-btn>
      </div>
      <div>
        <v-switch
            v-model="isGlobalPercentComputed"
            :label="isGlobalPercentComputed ? 'percent' : 'absolute values'"
        />
        <v-switch
            v-if="hasAnyAbility([
                $constants.ABILITY.DEVELOPER,
            ])"
            v-model="isUseWebsocket"
            label="use websockets"
        />
        <v-switch
            v-if="hasAnyAbility([
                $constants.ABILITY.DEVELOPER,
            ])"
            v-model="isUseAlternativeTable"
            label="use alternative table"
            @change="toggleAlternativeTable"
        />
        <v-switch
            v-if="false"
            v-model="isShowAlternativeDataComputed"
            :label="isShowAlternativeDataComputed
              ? 'switch to main data'
              : 'switch to alternative data'"
        />
        <v-switch
            v-if="hasAnyAbility([
                $constants.ABILITY.DEVELOPER,
            ])"
            v-model="isShowTrueDataComputed"
            :label="isShowTrueDataComputed ? 'switch to partly main data' : 'switch to main data'"
        />
      </div>
    </v-row>
    <v-row
      v-if="hasAnyAbility([
          $constants.ABILITY.BIGQUERY_MANAGEMENT,
          $constants.ABILITY.BIGQUERY_SELECTOR,
      ])"
    >
      <div
          style="width: 300px"
          class="mr-10"
      >
        <v-select
            label="BigQuery table"
            :items="bigQueryTablesList"
            v-model="currentBigQueryTable"
            item-text="caption"
            item-value="id"
            return-object
            @change="changeFilters"
        />
      </div>
      <div
          style="width: 350px"
          class="mr-10"
      >
        {{ currentBigQueryTable.description }} {{ currentBigQueryTable.is_main_default
          ? '[DEFAULT]' : '' }}
      </div>
      <div
          style="width: 300px"
          class="mr-10"
          v-if="isUseAlternativeTable"
      >
        <v-select
            label="Alternative BigQuery table"
            :items="bigQueryTablesList"
            v-model="alternativeBigQueryTable"
            item-text="caption"
            item-value="id"
            return-object
        />
      </div>
      <div
          style="width: 350px"
          class="mr-10"
          v-if="isUseAlternativeTable"
      >
        {{ alternativeBigQueryTable.description }}
        {{ alternativeBigQueryTable.is_alternative_default
          ? '[DEFAULT]' : '' }}
      </div>
    </v-row>
    <v-row
        v-if="hasAnyAbility([$constants.ABILITY.LOG_VERSION_MANAGEMENT])"
    >
      <div
          style="width: 300px"
          class="mr-10"
      >
        <v-select
            label="Log version"
            :items="logVersionsList"
            v-model="currentLogVersions"
            item-text="name"
            item-value="id"
            multiple
            @change="changeFilters"
            :menu-props="{ closeOnContentClick: true }"
        />
      </div>
    </v-row>
    <v-row
      v-if="!isCompositeCustomerHasAirport && hasAnyAbility([$constants.ABILITY.DEVELOPER])"
    >
      <div
          style="width: 300px"
          class="mr-10"
      >
        <v-row
          justify="start"
          align="center"
        >
          <v-select
              label="Destination filter"
              :items="routeDestinationsFilter()"
              v-model="queryFilters.routeDestinations.data"
              item-text="caption"
              item-value="id"
              multiple
              return-object
              :menu-props="{ closeOnContentClick: true }"
          />
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  v-bind="attrs"
                  v-on="on"
                  @click="getRouteDestinations"
                  icon
              >
                <v-icon
                    color="primary"
                >
                  mdi-refresh
                </v-icon>
              </v-btn>
            </template>
            update current customers des
          </v-tooltip>
        </v-row>
      </div>
      <div
          v-if="false"
          style="width: 300px"
          class="mr-10"
      >
        <v-select
            label="Tail numbers filter"
            :items="tailNumbersFilter"
            v-model="queryFilters.tailNumbers.data"
            multiple
            item-text="caption"
            item-value="id"
            return-object
        />
      </div>
    </v-row>
    <v-row
      v-if="isDashboardLoaded"
    >
      <v-col>
        <v-tabs
            v-model="currentAnalyticsTab"
        >
          <v-tab
              v-for="(tab, key) in dashboardStructure.tabs"
              :key="key"
          >
            {{ tab.name }}
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>

    <div class="main-body"
         v-if="isDashboardLoaded"
    >
      <LayoutBuilder
        v-if="dashboardStructure.static.is_shown"
        :layout-item="dashboardStructure.static"
      />

      <v-tabs-items
          v-model="currentAnalyticsTab"
      >
        <v-tab-item
            v-for="(tab, key) in dashboardStructure.tabs"
            :key="key"
        >
          <LayoutBuilder
              :layout-item="tab"
          />
        </v-tab-item>
      </v-tabs-items>

    </div>
    <LinearProgressNum
        :show="loading"
        :progress="loadedMetricsPercent"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import LinearProgressNum from '../components/LinearProgressNum.vue';
import LayoutBuilder from '../components/dasboard/layouts/LayoutBuilder.vue';

export default {
  name: 'Analytics',

  components: {
    LayoutBuilder,
    LinearProgressNum,
  },

  data() {
    return ({
      isUseWebsocket: false,
      isUseAlternativeTable: true,
      reportMetrics: [],
      isFiltersChanged: true,

      isDashboardLoaded: false,
      dashboardStructure: {},

      queryFilters: {
        routeDestinations: {
          isActive: false,
          data: [{
            caption: 'empty',
            id: null,
          }],
        },
        tailNumbers: {
          isActive: false,
          data: [{
            caption: 'empty',
            id: null,
          }],
        },
      },

      requestMetrics: [],

      currentAnalyticsTab: 1,

      loading: false,

      periodSelect: [
        { text: 'Today', value: 'today' },
        { divider: true },
        { text: 'Last day', value: 'hour_24' },
        { text: 'Last 7 days', value: 'day_7' },
        { text: 'Last 30 days', value: 'day_30' },
        { divider: true },
        { text: 'Month to Date', value: 'this-month' },
        { text: 'Last month', value: 'month_1' },
        { text: 'Last 6 months', value: 'month_6' },
        { text: 'Last 12 months', value: 'month_12' },
        { divider: true },
        { text: 'Year to Date', value: 'this-year' },
        { divider: true },
        { text: 'Custom range', value: 'custom' },
      ],

      monthFullNames: ['January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December',
      ],
      dayOfWeekNames: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      customDateRange: [],
      isDatepickerDialogOpen: false,
      isDisabledNextButton: true,
      currentDate: null,
      beginDate: null,
      endDate: null,
      wasScrollButtonsDisabled: true,
      previouslyShownDate: null,
      bigQueryTablesList: [],
      logVersionsList: [],

      currentBigQueryTable: {
        id: null,
        caption: '',
      },
      alternativeBigQueryTable: {
        id: null,
        caption: '',
      },
      currentLogVersions: [],

      randomKey: '',
    });
  },

  async created() {
    this.randomKey = this.generateRandomKey();

    await this.initPage();
  },

  methods: {
    ...mapActions({
      setSnack: 'snackbar/setState',
      resetCompositeCustomersList: 'compositeCustomers/resetCompositeCustomersList',
      clearMetricsData: 'metrics/clearMetricsData',
      setMetricsData: 'metrics/setMetricsData',
      setMetricData: 'metrics/setMetricData',
      setIsShowAlternativeData: 'controls/setIsShowAlternativeData',
      setIsShowTrueData: 'controls/setIsShowTrueData',
      setIsGlobalPercent: 'controls/setIsGlobalPercent',
      setPeriodSelected: 'controls/setPeriodSelected',
      setAirportAppUrl: 'metrics/setAirportAppUrl',
      setRouteDestinations: 'metrics/setRouteDestinations',
      setIsUseStaticMetricsData: 'metrics/setIsUseStaticMetricsData',
      setIsCompositeCustomerHasAirport: 'metrics/setIsCompositeCustomerHasAirport',
    }),

    async fetchDashboardStructure() {
      const response = await this.axios
        .post(
          'api/admin/dashboard/getCompositeCustomerData',
          {
            composite_customer_id: this.currentCompositeCustomer.id,
          },
        );

      this.dashboardStructure = JSON.parse(JSON.stringify(response.data.dashboard));
      await this.setIsCompositeCustomerHasAirport(response.data.options.has_airport);
      await this.setAirportAppUrl(response.data.options.app_url);

      this.isDashboardLoaded = true;
    },

    formattedDate(date = new Date()) {
      const year = date.getFullYear().toString();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');

      return `${year}-${month}-${day}`;
    },

    generateRandomKey() {
      return Math.floor(Math.random() * Date.now()).toString(36);
    },

    getWsData(metricsList) {
      this.loading = true;

      const requestMetrics = this.prepareRequestMetrics(metricsList);

      const options = {
        composite_customer: this.currentCompositeCustomer.id,
        versions: this.currentLogVersions,
        response_type: 'websockets',
        channel_key: this.randomKey,
        filters: this.extraFilters,
      };

      this.startListenWs();
      // send metrics
      this.$requestHelpers
        .post(
          'api/admin/analytics/getData',
          {
            metrics: requestMetrics,
            options,
          },
        ).catch((error) => {
          console.log(error);
          this.loading = false;
          this.stopListenWs();
        });
    },

    startListenWs() {
      const requestNumber = this.reportMetrics.length;
      let responseNumber = 0;
      this.$websocket.Echo().private('metric-data')
        .listen(`.data-user-${this.userId}-${this.randomKey}`, (event) => {
          const { metricKey } = event.metricData;

          const progressMetric = this.reportMetrics.findIndex(
            // eslint-disable-next-line max-len
            (el) => el.metricKey === metricKey,
          );

          const metricData = {
            data: event.metricData.result,
            status: true,
          };

          // this.$set(this.metricsData, metricKey, metricData);
          this.setMetricData({ metricKey, metricData });

          this.reportMetrics[progressMetric].status = 1;
          this.reportMetrics[progressMetric].error = event.metricData.error;
          this.reportMetrics[progressMetric].errorInfo = '';
          // update metrics data
          // if all metrics received - close listening
          // if fatal error received - close listening
          responseNumber += 1;

          if (responseNumber >= requestNumber) {
            this.stopListenWs();
          }
        });
    },

    async stopListenWs() {
      this.changeFilters(false);

      this.loading = false;

      const isRequestErrors = this.reportMetrics.reduce(
        (accum, cur) => accum || cur.error,
        0,
      );

      if (isRequestErrors) {
        await this.setSnack({
          snack: true,
          color: 'red',
          text: `Couldn't get data from: ${this.reportMetrics.filter((el) => el.error)
            .map((el) => el.name).join(', ')}`,
        });
      }

      this.$websocket.Echo().private('metric-data')
        .stopListening(`.data-user-${this.userId}-${this.randomKey}`);
    },

    async initPage() {
      await this.setIsUseStaticMetricsData(false);
      await this.resetCompositeCustomersList();
      await this.getBigQueryTablesList();
      await this.getLogVersionsList();

      this.periodSelectedComputed = 'day_7';
      if (this.hasAllAbilities([this.$constants.ABILITY.CUSTOMER])) {
        this.periodSelectedComputed = 'day_30';
        this.isGlobalPercentComputed = true;
      }
      this.currentAnalyticsTab = 0;

      this.currentBigQueryTable = this.bigQueryTablesList.length
        ? this.bigQueryTablesList.find((el) => el.is_main_default === 1)
        : {
          id: null,
          caption: '',
          description: '',
        };

      this.setAlternativeTable();

      this.currentLogVersions = [-1];

      await this.initBoundsTime();

      await this.fetchDashboardStructure();

      if (this.hasAllAbilities([this.$constants.ABILITY.CUSTOMER])) {
        await this.getData();
      }
    },

    async fulfillWithTimeLimit(task, timeLimit = 20000, failureValue = null) {
      let timeout;
      const timeoutPromise = new Promise((resolve) => {
        timeout = setTimeout(() => {
          resolve(failureValue);
        }, timeLimit);
      });

      const response = await Promise.race([task, timeoutPromise]);

      if (timeout) {
        clearTimeout(timeout);
      }

      return response;
    },

    async fulfillWithCounter(task, requestedMetric) {
      const itemIndex = this.reportMetrics
        .findIndex((el) => el.metricKey === requestedMetric.metricKey);

      return new Promise((resolve) => {
        task.then((data) => {
          // eslint-disable-next-line no-param-reassign
          this.reportMetrics[itemIndex].error = 0;
          resolve(data);
        }).catch((error) => {
          // eslint-disable-next-line no-param-reassign
          this.reportMetrics[itemIndex].error = 1;
          // eslint-disable-next-line no-param-reassign
          this.reportMetrics[itemIndex].errorInfo = error;

          resolve({
            data: {
              result: [],
            },
            error: true,
          });
        }).finally(() => {
          // eslint-disable-next-line no-param-reassign
          this.reportMetrics[itemIndex].status = 1;
        });
      });
    },

    async getBigQueryTablesList() {
      const response = await this.axios
        .get('api/admin/bigquery/list');

      this.bigQueryTablesList = response.data;
    },

    async getLogVersionsList() {
      const response = await this.axios
        .get('api/admin/logVersion/list');

      this.logVersionsList = response.data;
    },

    async getRouteDestinations() {
      await this.setRouteDestinations(await this.getAnalytics(this.extraParameters, [], false));
    },

    changeFilters(flag = true) {
      this.isFiltersChanged = flag;
    },

    collectDashboardMetrics() {
      const requiredMetrics = [];

      if (this.dashboardStructure.static.is_shown) {
        const positions = Object.values(this.dashboardStructure.static.layout.positions);

        positions.forEach((position) => {
          Object.values(position.metric_items).forEach((metricItem) => {
            if (metricItem.metrics) {
              requiredMetrics.push(...metricItem.metrics);
            }
          });
        });
      }

      Object.values(this.dashboardStructure.tabs).forEach((tab) => {
        const positions = Object.values(tab.layout.positions);

        positions.forEach((position) => {
          Object.values(position.metric_items).forEach((metricItem) => {
            if (metricItem.metrics) {
              requiredMetrics.push(...metricItem.metrics);
            }
          });
        });
      });

      return [...new Set(requiredMetrics)];
    },

    filterMetrics(metrics, metricSource) {
      return metrics
        .filter((metricSlug) => (this.isCompositeCustomerHasAirport && metricSource[metricSlug].customerType.includes('airport'))
        || (!this.isCompositeCustomerHasAirport && metricSource[metricSlug].customerType.includes('airplane')));
    },

    prepareRequestMetrics(sourceMetrics, requestedMetricAliases = []) {
      this.reportMetrics = [];

      const requestMetrics = [];

      this.currentDate = new Date();

      const curBeginTimestamp = this.beginDate.getTime();

      const curEndTimestamp = this.endDate.getTime();

      // subtracted 1 ms compensation
      const timeDiff = curEndTimestamp - curBeginTimestamp + 1;

      const curPrevBeginTimestamp = curBeginTimestamp - timeDiff;

      // prepare metrics
      const metricAliasesNotFiltered = requestedMetricAliases.length
        ? requestedMetricAliases
        : Object.keys(sourceMetrics);

      const metricAliases = this.filterMetrics(metricAliasesNotFiltered, sourceMetrics);

      // eslint-disable-next-line no-restricted-syntax
      for (const alias of metricAliases) {
        // eslint-disable-next-line no-restricted-syntax
        for (const intervalType of sourceMetrics[alias].time_interval_types) {
          // eslint-disable-next-line no-restricted-syntax
          for (const dataSet of sourceMetrics[alias].data_sets) {
            if (!this.isUseAlternativeTable && dataSet === 'alternative') {
              // eslint-disable-next-line no-continue
              continue;
            }

            let bigQueryId = null;

            switch (dataSet) {
              case 'main':
                bigQueryId = this.currentBigQueryTable.id;
                break;
              case 'alternative':
                bigQueryId = this.alternativeBigQueryTable.id;
                break;
              default:
            }

            let beginTimestamp = intervalType === 'cur'
              ? curBeginTimestamp : curPrevBeginTimestamp;
            let endTimestamp = intervalType === 'cur'
              ? curEndTimestamp : curBeginTimestamp;

            if (alias === 'unique_users_date_particular') {
              beginTimestamp = (new Date(
                Date.UTC(
                  this.currentDate.getUTCFullYear(),
                  this.currentDate.getUTCMonth() - 2,
                  1, 0, 0, 0, 0,
                ),
              )).getTime();
              endTimestamp = this.currentDate.getTime();
            }

            const metricKey = `${alias}_${intervalType}_${dataSet}`;

            const preparedMetric = {
              requestType: sourceMetrics[alias].requestType,
              alias,
              metricKey,
              dataSet,
              bigquery: bigQueryId,
              beginTimestamp,
              endTimestamp,
            };

            requestMetrics.push(preparedMetric);

            const metricItem = {
              name: sourceMetrics[alias].taskName,
              status: 0,
              metricKey,
            };

            this.reportMetrics.push(metricItem);
          }
        }
      }

      return requestMetrics;
    },

    async getData() {
      await this.clearMetricsData();
      const dashboardMetrics = this.collectDashboardMetrics();

      if (this.isUseWebsocket) {
        this.getWsData(this.metricsList);
      } else {
        await this.setMetricsData(
          await this.getAnalytics(
            this.metricsList,
            dashboardMetrics,
          ),
        );
      }
    },

    async getAnalytics(metricsList, requestedMetricAliases = [], isNeedUpdateFilters = true) {
      const metricsData = [];
      this.loading = true;

      this.currentDate = new Date();

      // eslint-disable-next-line max-len
      const requestMetrics = this.prepareRequestMetrics(metricsList, requestedMetricAliases);

      try {
        const metricsResults = await this.analyticsResolve(requestMetrics);

        // eslint-disable-next-line no-restricted-syntax
        for (const metricResult of metricsResults) {
          if (metricResult.value.error !== undefined && metricResult.value.error === true) {
            // eslint-disable-next-line no-continue
            continue;
          }

          const requestConfigData = JSON.parse(metricResult.value.config.data);
          const { metricKey } = requestConfigData.options;

          const metricData = metricResult.status === 'fulfilled'
            ? {
              data: metricResult.value.data.result,
              status: true,
            }
            : {
              data: [],
              status: false,
            };
          this.$set(metricsData, metricKey, metricData);
        }
      } catch (err) {
        console.log(err);
      }

      if (isNeedUpdateFilters) {
        this.changeFilters(false);
      }

      this.loading = false;

      const isRequestErrors = this.reportMetrics.reduce(
        (accum, cur) => accum || cur.error,
        0,
      );

      if (isRequestErrors) {
        await this.setSnack({
          snack: true,
          color: 'red',
          text: `Couldn't get data from: ${this.reportMetrics.filter((el) => el.error)
            .map((el) => el.name).join(', ')}`,
        });
      }

      return metricsData;
    },

    async analyticsResolve(requestMetrics) {
      const requestPromises = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const requestMetric of requestMetrics) {
        const currentPromise = this.fulfillWithCounter(
          this.getTimeSpreadRequest(requestMetric),
          requestMetric,
        );

        requestPromises.push(currentPromise);
      }

      return Promise.allSettled(requestPromises);
    },

    async getTimeSpreadRequest({
      requestType,
      alias,
      metricKey,
      dataSet,
      bigquery,
      beginTimestamp = null,
      endTimestamp = null,
      extraOptions = {},
    }) {
      const curBeginTimestamp = beginTimestamp === null
        ? this.beginDate.getTime()
        : beginTimestamp;
      const curEndTimestamp = endTimestamp === null
        ? this.endDate.getTime()
        : endTimestamp;

      const primeOptions = {
        composite_customer: this.currentCompositeCustomer.id,
        bigquery,
        versions: this.currentLogVersions,
        dataSet,
        alias,
        metricKey,
        beginTimestamp: curBeginTimestamp,
        endTimestamp: curEndTimestamp,
        filters: this.extraFilters,
      };

      const options = {
        ...primeOptions,
        ...extraOptions,
      };

      return this.axios
        .post('api/admin/analytics/getData',
          {
            type: requestType,
            options,
          });
    },

    async setPeriodStep(stepDirection) {
      switch (this.periodSelectedComputed) {
        case 'this-month':
        case 'month_1':
          if (stepDirection === 'prev') {
            this.setPreviousMonthBounds();
          } else {
            this.setNextMonthBounds();
          }
          break;
        case 'this-year':
          if (stepDirection === 'prev') {
            this.setPreviousYearBounds();
          } else {
            this.setNextYearBounds();
          }
          break;
        case 'today':
        default:
          if (stepDirection === 'prev') {
            this.setPreviousDayBounds();
          } else {
            this.setNextDayBounds();
          }
      }

      this.changeFilters();
    },

    async initBoundsTime() {
      this.isDisabledNextButton = true;

      this.endDate = new Date();

      switch (this.periodSelectedComputed) {
        case 'hour_12':
          this.beginDate = new Date(
            this.endDate.getTime() - 1000 * 60 * 60 * 12,
          );
          break;
        case 'hour_24':
          this.endDate.setUTCHours(0, 0, 0, 0);
          this.beginDate = new Date(
            this.endDate.getTime() - 1000 * 60 * 60 * 24,
          );
          this.endDate.setUTCHours(0, 0, 0, -1);

          break;
        case 'day_7':
          this.endDate.setUTCHours(0, 0, 0, 0);
          this.beginDate = new Date(
            this.endDate.getTime() - 1000 * 60 * 60 * 24 * 7,
          );
          this.endDate.setUTCHours(0, 0, 0, -1);

          break;
        case 'day_30':
          this.endDate.setUTCHours(0, 0, 0, 0);
          this.beginDate = new Date(
            this.endDate.getTime() - 1000 * 60 * 60 * 24 * 30,
          );
          this.endDate.setUTCHours(0, 0, 0, -1);

          break;
        case 'this-month':
          this.beginDate = new Date(
            Date.UTC(
              this.endDate.getUTCFullYear(),
              this.endDate.getUTCMonth(),
              1, 0, 0, 0, 0,
            ),
          );
          break;
        case 'month_1':
          this.setPreviousMonthBounds();
          break;
        case 'this-year':
          this.beginDate = new Date(
            Date.UTC(
              this.endDate.getUTCFullYear(),
              0, 1, 0, 0, 0, 0,
            ),
          );
          break;
        case 'month_6':
          this.beginDate = new Date(
            Date.UTC(
              this.endDate.getUTCFullYear(),
              this.endDate.getUTCMonth() - 6,
              this.endDate.getUTCDate(),
              this.endDate.getUTCHours(),
              this.endDate.getUTCMinutes(),
              this.endDate.getUTCSeconds(),
              this.endDate.getUTCMilliseconds(),
            ),
          );
          break;
        case 'month_12':
          this.beginDate = new Date(
            Date.UTC(
              this.endDate.getUTCFullYear(),
              this.endDate.getUTCMonth() - 12,
              this.endDate.getUTCDate(),
              this.endDate.getUTCHours(),
              this.endDate.getUTCMinutes(),
              this.endDate.getUTCSeconds(),
              this.endDate.getUTCMilliseconds(),
            ),
          );
          break;
        case 'custom':
          this.openDateRangeDialog();
          return false;
        case 'realtime':
        case 'all-time':
        case 'today':
        default:
          this.beginDate = new Date(
            Date.UTC(
              this.endDate.getUTCFullYear(),
              this.endDate.getUTCMonth(),
              this.endDate.getUTCDate(),
              0, 0, 0, 0,
            ),
          );
      }

      return true;
    },

    setPreviousDayBounds() {
      this.isDisabledNextButton = false;

      this.endDate.setUTCHours(0);
      this.endDate.setUTCMinutes(0);
      this.endDate.setUTCSeconds(0);
      this.endDate.setUTCMilliseconds(-1);

      this.beginDate = new Date(
        Date.UTC(
          this.endDate.getUTCFullYear(),
          this.endDate.getUTCMonth(),
          this.endDate.getUTCDate(),
          0, 0, 0, 0,
        ),
      );
    },

    setNextDayBounds() {
      const endTime = new Date(
        Date.UTC(
          this.endDate.getUTCFullYear(),
          this.endDate.getUTCMonth(),
          this.endDate.getUTCDate() + 2,
          0, 0, 0, -1,
        ),
      );
      const nowTime = Date.now();
      if (endTime.getTime() <= nowTime) {
        this.endDate = new Date(endTime.getTime());
      } else {
        this.isDisabledNextButton = true;
        this.endDate = new Date(nowTime);
      }

      this.beginDate = new Date(
        Date.UTC(
          this.endDate.getUTCFullYear(),
          this.endDate.getUTCMonth(),
          this.endDate.getUTCDate(),
          0, 0, 0, 0,
        ),
      );
    },

    setPreviousMonthBounds() {
      this.isDisabledNextButton = false;

      this.endDate.setUTCDate(1);
      this.endDate.setUTCHours(0);
      this.endDate.setUTCMinutes(0);
      this.endDate.setUTCSeconds(0);
      this.endDate.setUTCMilliseconds(-1);

      this.beginDate = new Date(
        Date.UTC(
          this.endDate.getUTCFullYear(),
          this.endDate.getUTCMonth(),
          1, 0, 0, 0, 0,
        ),
      );
    },

    setNextMonthBounds() {
      const endTime = new Date(
        Date.UTC(
          this.endDate.getUTCFullYear(),
          this.endDate.getUTCMonth() + 2,
          1, 0, 0, 0, -1,
        ),
      );
      const nowTime = Date.now();
      if (endTime.getTime() <= nowTime) {
        this.endDate = new Date(endTime.getTime());
      } else {
        this.isDisabledNextButton = true;
        this.endDate = new Date(nowTime);
      }

      this.beginDate = new Date(
        Date.UTC(
          this.endDate.getUTCFullYear(),
          this.endDate.getUTCMonth(),
          1, 0, 0, 0, 0,
        ),
      );
    },

    setPreviousYearBounds() {
      this.isDisabledNextButton = false;

      this.endDate.setUTCMonth(0);
      this.endDate.setUTCDate(1);
      this.endDate.setUTCHours(0);
      this.endDate.setUTCMinutes(0);
      this.endDate.setUTCSeconds(0);
      this.endDate.setUTCMilliseconds(-1);

      this.beginDate = new Date(
        Date.UTC(
          this.endDate.getUTCFullYear(),
          0, 1, 0, 0, 0, 0,
        ),
      );
    },

    setNextYearBounds() {
      const endTime = new Date(
        Date.UTC(
          this.endDate.getUTCFullYear() + 2,
          0,
          0, 0, 0, 0, -1,
        ),
      );
      const nowTime = Date.now();
      if (endTime.getTime() <= nowTime) {
        this.endDate = new Date(endTime.getTime());
      } else {
        this.isDisabledNextButton = true;
        this.endDate = new Date(nowTime);
      }

      this.beginDate = new Date(
        Date.UTC(
          this.endDate.getUTCFullYear(),
          0, 1, 0, 0, 0, 0,
        ),
      );
    },

    async selectPeriod() {
      if (await this.initBoundsTime()) {
        this.changeFilters();
      }
    },

    openDateRangeDialog() {
      this.customDateRange = [];
      this.isDatepickerDialogOpen = true;
    },

    closeDateRangeDialog() {
      this.isDatepickerDialogOpen = false;
    },

    cancelDateRangeDialog() {
      this.closeDateRangeDialog();
    },

    async saveDataRange() {
      const firstDate = new Date(Date.parse(`${this.customDateRange[0]} UTC`));
      const secondDate = new Date(Date.parse(`${this.customDateRange[1]} UTC`));

      [this.beginDate, this.endDate] = [firstDate, secondDate]
        .sort((a, b) => a.getTime() - b.getTime());

      this.endDate.setUTCHours(24, 0, 0, -1);

      this.changeFilters();
      this.closeDateRangeDialog();
    },

    convertDateToUTC(date) {
      return new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds(),
      );
    },

    async changeCustomer() {
      this.changeFilters();
      await this.fetchDashboardStructure();
    },

    saveAsPdf() {
      const options = {
        backgroundColor: null,
        allowTaint: true,
        useCORS: true,
      };

      // eslint-disable-next-line new-cap
      const doc = new jsPDF('l', 'px', 'a4');
      const width = doc.internal.pageSize.getWidth();
      const height = doc.internal.pageSize.getHeight();

      html2canvas(this.$refs.topdf.$el, options).then((canvas) => {
        const imgData = canvas.toDataURL('image/png', 1.0)
          .replace('image/png', 'image/octet-stream');

        doc.addImage(imgData, 'PNG', 0, 0, width, height);
        doc.save(`${this.currentCompositeCustomer.name}.pdf`);
      });
    },

    toggleAlternativeTable() {
      this.changeFilters();

      if (!this.isUseAlternativeTable) {
        this.isShowAlternativeDataComputed = false;
      }

      if (this.alternativeBigQueryTable.id != null) {
        return false;
      }

      this.setAlternativeTable();

      return false;
    },

    setAlternativeTable() {
      if (this.isUseAlternativeTable) {
        this.alternativeBigQueryTable = this.bigQueryTablesList.length
          ? this.bigQueryTablesList.find((el) => el.is_alternative_default === 1)
          : {
            id: null,
            caption: '',
            description: '',
          };
      }
    },

    exportData() {
      this.$requestHelpers
        .post(
          'api/admin/export',
          {
            composite_customer_id: this.currentCompositeCustomer.id,
            timestamp_begin: this.beginDate.getTime(),
            timestamp_end: this.endDate.getTime(),
          },
          {
            responseType: 'blob',
          },
        )
        .then((response) => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement('a');
          const headerval = response.headers['content-disposition'];
          const filename = headerval.match(/filename=(.*)/);

          fileLink.href = fileURL;
          fileLink.setAttribute('download', filename[1]);
          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },

  async deactivated() {
    if (this.$websocket.initEcho) {
      await this.stopListenWs();
    }

    this.$destroy();
  },

  computed: {
    ...mapGetters({
      compositeCustomersList: 'compositeCustomers/compositeCustomersList',
      userId: 'auth/userId',
      periodSelected: 'controls/periodSelected',
      isGlobalPercent: 'controls/isGlobalPercent',
      isShowAlternativeData: 'controls/isShowAlternativeData',
      isShowTrueData: 'controls/isShowTrueData',
      activeDataset: 'controls/activeDataset',
      intervalNotation: 'controls/intervalNotation',
      airportAppUrl: 'metrics/airportAppUrl',
      metricsList: 'metrics/metricsList',
      extraParameters: 'metrics/extraParameters',
      metricsData: 'metrics/metricsData',
      routeDestinationsFilter: 'metrics/routeDestinationsFilter',
      isCompositeCustomerHasAirport: 'metrics/isCompositeCustomerHasAirport',
    }),

    isShowTrueDataComputed: {
      get() {
        return this.isShowTrueData;
      },

      set(value) {
        this.setIsShowTrueData(value);
      },
    },

    periodSelectedComputed: {
      get() {
        return this.periodSelected;
      },

      set(value) {
        this.setPeriodSelected(value);
      },
    },

    isGlobalPercentComputed: {
      get() {
        return this.isGlobalPercent;
      },

      set(value) {
        this.setIsGlobalPercent(value);
      },
    },

    isShowAlternativeDataComputed: {
      get() {
        return this.isShowAlternativeData;
      },

      set(value) {
        this.setIsShowAlternativeData(value);
      },
    },

    extraFilters() {
      return [
        {
          type: 'by_destination',
          data: this.queryFilters
            .routeDestinations.data
            .map((el) => el.id)
            .filter((el) => el !== null),
        },
      ];
    },

    currentCompositeCustomer: {
      get() {
        return this.$store.state.compositeCustomers.currentCompositeCustomer;
      },

      set(value) {
        this.$store.commit('compositeCustomers/SET_CURRENT_COMPOSITE_CUSTOMER', value);
      },
    },

    loadedMetricsPercent() {
      const total = this.reportMetrics.length;

      const loaded = this.reportMetrics.reduce((accum, cur) => {
        if (cur.status === 1) return accum + 1;
        return accum;
      },
      0);

      return total ? ((loaded * 100) / total).toFixed(0) : 0;
    },

    isEmptyCustomDateRange() {
      return this.customDateRange.length < 2;
    },

    isScrollButtonsDisabled() {
      switch (this.periodSelectedComputed) {
        case 'reset':
          return this.wasScrollButtonsDisabled;
        case 'today':
        case 'day':
        case 'this-month':
        case 'month_1':
        case 'this-year':
          return false;
        default:
          return true;
      }
    },

    shownDate() {
      if (!this.beginDate) {
        return '';
      }
      switch (this.periodSelectedComputed) {
        case 'reset':
          return this.previouslyShownDate;
        case 'today':
        case 'day':
          return `${this.dayOfWeekNames[this.beginDate.getUTCDay()]},
           ${this.beginDate.getUTCDate()} ${this.monthFullNames[this.beginDate.getUTCMonth()]}`;
        case 'this-month':
        case 'month_1':
          return `${this.monthFullNames[this.beginDate.getUTCMonth()]} ${this.beginDate.getUTCFullYear()}`;
        case 'this-year':
          return `Year of ${this.beginDate.getUTCFullYear()}`;
        case 'month_6':
        case 'month_12':
        case 'hour_24':
        case 'day_7':
        case 'day_30':
        case 'custom':
          return `${this.monthFullNames[this.beginDate.getUTCMonth()]} ${this.beginDate.getUTCDate()}, ${this.beginDate.getUTCFullYear()} -
          ${this.monthFullNames[this.endDate.getUTCMonth()]} ${this.endDate.getUTCDate()}, ${this.endDate.getUTCFullYear()}`;
        default:
          return '';
      }
    },
  },
};
</script>

<style>
input[type="radio"] {
  vertical-align: text-bottom;
}

input[type="checkbox"] {
  vertical-align: middle;
}

label, input[type=button], input[type=submit], button {
  cursor: pointer;
}

button, input, select, textarea {
  margin: 0;
}

button {
  width: auto;
  overflow: visible;
}

.clearfix:before, .clearfix:after {
  content: "\0020";
  display: block;
  height: 0;
  visibility: hidden;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  zoom: 1;
}

html {
  height: 100%;
}

body {
  width: 100%;
  min-height: 100%;
  height: 100%;
  font-family: 'Rubik', sans-serif;
}

strong {
  font-weight: 700;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.hidden {
  display: none;
}

.invisible {
  visibility: hidden;
}

.transparent {
  opacity: 0;
}
</style>

<style scoped>
.item-clicker:hover {
  cursor: pointer;
}

</style>
